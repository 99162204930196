import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import LibraryCategory from '~containers/browse-library/LibraryOverviewPage/LibraryCategory';
import useLibraryOverviewStore from '~hooks/store/useLibraryOverviewStore';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%'
  }
}));

const LibraryCategories = ({ sections }) => {
  const classes = useStyles();
  const selectedSectionUid = useLibraryOverviewStore(state => state.selectedSectionUid);

  const filteredSections = useMemo(
    () =>
      selectedSectionUid
        ? sections.filter(section => section.uid === selectedSectionUid)
        : sections,
    [selectedSectionUid, sections]
  );

  return (
    <div className={classes.root}>
      {filteredSections.map(section => (
        <LibraryCategory
          key={section.uid}
          url={section.url}
          title={section.title}
          specialLinks={section.specialLinks}
          resources={section.highlightedResources}
        />
      ))}
    </div>
  );
};

LibraryCategories.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      uid: PropTypes.string,
      specialLinks: PropTypes.arrayOf(PropTypes.shape({})),
      highlightedResources: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ).isRequired
};

LibraryCategories.defaultProps = {};

export default LibraryCategories;

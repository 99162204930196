import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ClickableText from '~components/buttons/ClickableText';
import AllIcon from '~images/icons/all-icon.svg';
import clsx from '~styles/clsx';
import useLibraryOverviewStore from '~hooks/store/useLibraryOverviewStore';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    gap: '14px'
  },
  title: {
    color: '#202124',
    fontSize: 42,
    fontFamily: 'Inter',
    fontWeight: '700',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24
    }
  },
  sectionPills: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
    flexWrap: 'wrap',
    '& > span:first-child': {
      marginRight: 16
    }
  },
  pill: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    borderRadius: 30,
    color: '#2A2A2A',
    fontSize: 14,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontWeight: '500',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E1E1E1'
    }
  },
  selectedPill: {
    outline: '1px black solid'
  }
}));

const LibraryHeader = ({ title, sections }) => {
  const classes = useStyles();
  const [selectedSectionUid, updateSelectedSectionUid] = useLibraryOverviewStore(state => [
    state.selectedSectionUid,
    state.updateSelectedSectionUid
  ]);

  const onSectionClick = useCallback(
    uid => {
      updateSelectedSectionUid(uid);
    },
    [updateSelectedSectionUid]
  );

  const sectionsWithDefaultAllSection = useMemo(
    () => [{ uid: null, title: 'All' }, ...sections],
    [sections]
  );

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.sectionPills}>
        {sectionsWithDefaultAllSection.map(section => (
          <ClickableText
            key={sections.uid}
            onClick={() => onSectionClick(section.uid)}
            className={clsx(
              classes.pill,
              selectedSectionUid === section.uid && classes.selectedPill
            )}
          >
            {section.uid === null && <img src={AllIcon} alt="all" />}
            {section.title}
          </ClickableText>
        ))}
      </div>
    </div>
  );
};

LibraryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      uid: PropTypes.string
    })
  ).isRequired
};

export default LibraryHeader;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Link from '~components/Link';
import { useLocation } from '@reach/router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '20px',
    marginTop: 80,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
      marginBottom: 28
    }
  },
  link: {
    color: '#989898',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    textTransform: 'capitalize',
    lineHeight: '27px'
  },
  activeLink: {
    color: '#111111',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    textTransform: 'capitalize',
    lineHeight: '27px'
  }
}));

const LibraryTopNavigation = ({ links }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      {links.map(link => (
        <Link
          key={link?.title}
          to={link?.url}
          className={location.pathname.includes(link?.url) ? classes.activeLink : classes.link}
          underline="none"
        >
          {link?.title}
        </Link>
      ))}
    </div>
  );
};

LibraryTopNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default LibraryTopNavigation;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Carrot from '~images/buttons/carrot.inline.svg';
import Link from '~components/Link';
import LibraryCategoryItem from '~components/cards/LibraryCategoryItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 48,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 30
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    gap: 10
  },
  specialLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 10,
    marginRight: 72,
    [theme.breakpoints.down('sm')]: {
      marginRight: 16
    }
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  link: {
    color: '#4BA5FF',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600',
    '&:hover': {
      color: '#408fdd',
      textDecoration: 'underline'
    }
  },
  resourceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginTop: 13,
    marginBottom: 13
  },
  seeMoreLink: {
    margin: 'auto',
    background: 'white',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    width: 'fit-content',
    padding: '9px 19px',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  }
}));

const LibraryCategory = ({ title, url, specialLinks, resources }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.titleContainer}>
        <Link to={url} underline="none">
          <h3 className={classes.title}>
            {title} <Carrot stroke="#000000" />
          </h3>
        </Link>
        <div className={classes.specialLinkContainer}>
          {specialLinks.map(link => (
            <Link key={link?.title} to={link?.url} className={classes.link} underline="none">
              {link?.title}
            </Link>
          ))}
        </div>
      </div>
      <div className={classes.resourceContainer}>
        {resources.map(resource => (
          <LibraryCategoryItem key={resource.uid} {...resource} />
        ))}
      </div>
      <Link to={url} className={classes.seeMoreLink} underline="none">
        <span>Browse All {title} </span>
        <Carrot stroke="#000000" />
      </Link>
    </section>
  );
};

LibraryCategory.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  specialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      url: PropTypes.string,
      isSubscriberResource: PropTypes.bool,
      urlTarget: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      resourceMedium: PropTypes.string.isRequired,
      duration: PropTypes.string,
      episodeCountText: PropTypes.string,
      metaText: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      })
    })
  )
};

LibraryCategory.defaultProps = {
  specialLinks: [],
  resources: []
};

export default LibraryCategory;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClickableText from '~components/buttons/ClickableText';
import ExpandCarrot from '~images/buttons/downwards-carrot.inline.svg';
import clsx from '~styles/clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 0
  },
  expandCarrot: {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    alignSelf: 'end',
    '&:hover': {
      backgroundColor: '#ebebeb'
    }
  },
  clickableTextContainer: {
    cursor: 'pointer'
  },
  clampedContent: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  content: {
    color: '#242424',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '21px',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '520px'
  }
});

const DescriptionText = ({ text }) => {
  const classes = useStyles();
  const textRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(false);

  const onClick = useCallback(
    event => {
      if (isTextClamped && event.stopPropagation) {
        event.stopPropagation();
        event.preventDefault();
      }
      setExpanded(!expanded);
    },
    [expanded, isTextClamped]
  );

  useEffect(() => {
    if (textRef?.current) {
      setIsTextClamped(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [text]);

  return (
    <div className={classes.root}>
      <p ref={textRef} className={clsx(classes.content, !expanded && classes.clampedContent)}>
        {text}
      </p>
      {isTextClamped && !expanded ? (
        <ClickableText component="div" onClick={onClick} className={classes.expandCarrot}>
          <ExpandCarrot />
        </ClickableText>
      ) : null}
    </div>
  );
};

DescriptionText.propTypes = {
  text: PropTypes.string.isRequired
};

DescriptionText.defaultProps = {};

export default DescriptionText;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Link from '~components/Link';
import PlaceholderImage from '~images/placeholders/resource_card_placeholder.png';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getResourceMediumIcon } from '~src/utils/resource-helpers';
import { Tooltip } from '@material-ui/core';
import DescriptionText from './DescriptionText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '48px',
    padding: '24px 44px',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: 'rgba(242, 242, 242, 0.60)'
    },
    [theme.breakpoints.down('xs')]: {
      gap: '16px',
      padding: '24px 0px'
    }
  },
  title: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '520px'
  },
  infoText: {
    color: '#747474',
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: '500',
    marginTop: 0,
    marginBottom: 0
  },
  image: {
    maxWidth: '102px',
    borderRadius: 5,
    objectFit: 'contain',
    alignSelf: 'start'
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    flex: 14
  },
  resourceType: {
    minWidth: '36px'
  },
  resourceMeta: {
    flex: 4
  },
  mobileResourceMeta: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '4px',
    alignItems: 'center'
  },
  tooltip: {
    fontSize: '14px',
    backgroundColor: '#222222'
  },
  tooltipArrow: {
    color: '#222222'
  }
}));

const LibraryCategoryItem = props => {
  const classes = useStyles();
  const {
    uid,
    title,
    url: originalUrl,
    urlTarget: originalUrlTarget,
    image,
    resourceMedium,
    duration,
    episodeCountText,
    metaText,
    description,
    isSubscriberResource
  } = props;
  const { marketingUser } = useUserContext().session;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const TypeIcon = getResourceMediumIcon(resourceMedium);
  const hasAccess = !isSubscriberResource || marketingUser?.isSubscriber;
  const url = hasAccess ? originalUrl : '/subscribe';
  const urlTarget = hasAccess ? originalUrlTarget : '_self';
  const infoText =
    [isSmallerScreen && resourceMedium, episodeCountText, duration].filter(Boolean).join(' • ') ||
    '';

  return (
    <Link to={url} target={urlTarget || '_self'} className={classes.root} underline="none" id={uid}>
      <img src={image?.url || PlaceholderImage} alt={image?.alt} className={classes.image} />
      <div className={classes.cardInfo}>
        <h4 className={classes.title}>{title}</h4>
        <DescriptionText text={description} maxLines={2} />
        {isSmallerScreen && (
          <div className={classes.mobileResourceMeta}>
            <TypeIcon />
            <p className={classes.infoText}>{metaText}</p>
          </div>
        )}
      </div>

      {!isSmallerScreen && (
        <>
          <Tooltip
            title={resourceMedium}
            arrow
            placement="top"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <div>
              <TypeIcon />
            </div>
          </Tooltip>

          <div className={classes.resourceMeta}>
            <p className={classes.infoText}>{infoText}</p>
          </div>
        </>
      )}
    </Link>
  );
};

LibraryCategoryItem.propTypes = {
  uid: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isSubscriberResource: PropTypes.bool,
  urlTarget: PropTypes.string,
  title: PropTypes.string.isRequired,
  resourceMedium: PropTypes.string.isRequired,
  duration: PropTypes.string,
  episodeCountText: PropTypes.string,
  metaText: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

LibraryCategoryItem.defaultProps = {
  isSubscriberResource: false,
  urlTarget: '_self',
  metaText: '',
  description: '',
  duration: '',
  episodeCountText: '',
  image: null
};

export default LibraryCategoryItem;

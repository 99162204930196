import React from 'react';

const makePageEnabledEnforcer =
  (isFeatureEnabled, PageComponent) =>
  // eslint-disable-next-line
  ({ data, ...props }) => {
    if (!isFeatureEnabled) {
      return null;
    }

    return <PageComponent data={data} {...props} />;
  };

export default makePageEnabledEnforcer;
